import React, { useState } from "react"
import Footer from "../../components/footer"
import { graphql, navigate } from "gatsby"
import "../../styles/solutions-partner.less"
import SEO from "../../components/SEO"
import { SolutionTopBanner } from "../../components/block"
import { MapContainer } from "../index"
import { Join } from "./reseller-partner"
const loadText = require("src/utils").loadText
const text = loadText("solutions-partner")
const url = loadText("url")
import Drop from "../../components/drop"
import { PartnerPageForm, PartnerPopButton } from "../../components/form-biz"

const MiddleBanner = () => (
  <div className="solutionsPartner-middleBanner">
    <div className="page-middle-bg">
      <div className="page-middle solutionsPartner-middleBanner-content">
        <div className="font-36 semi-bold">{text.middleBanner.title}</div>
        <div className="font-20 mt-24" style={{ maxWidth: "624px" }}>
          {text.middleBanner.description}
        </div>
      </div>
    </div>
  </div>
)
const Feature = () => {
  return (
    <div className="solutionsPartner-feature full-bg">
      <div className="page-middle">
        <div className="solutionsPartner-feature-bg"></div>
        <div className="solutionsPartner-feature-content block-middle">
          {text.feature.map(({ name, description }, idx) => (
            <div
              className={`solutionsPartner-feature-item mobile-padding solutionsPartner-feature-item-${idx}`}
            >
              <div className="imgContainer">
                <picture>
                  <source
                    srcSet={`/partners/solutions/feature-${idx + 1}-m.png 640w`}
                    media="(max-width: 640px)"
                  />
                  <img
                    src={`/partners/solutions/feature-${idx + 1}.png`}
                    alt={name}
                  />
                </picture>
              </div>
              <div className="textContainer">
                <div className="font-36 semi-bold t-color">{name}</div>
                <div className="mt-24 font-20">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const Service = () => {
  const [active, setActive] = useState("")
  return (
    <div className="solutionsPartner-services">
      <div className="semi-bold font-36 t-color">{text.services.title}</div>
      <div>
        {text.services.content.map(
          ({ name, description, key, color, hoverColor }) => (
            <div
              className={`solutionsPartner-services-item ${
                active === key ? "active" : ""
              }`}
              onClick={() => {
                window.open(url[key].link)
              }}
              onMouseEnter={() => {
                setActive(key)
              }}
            >
              <div className="solutionsPartner-services-visible">
                <div className="pic-container" style={{ background: color }}>
                  <img src={`/partners/solutions/${key}.svg`} alt={name} />
                </div>
                <div className="text-container font-28 semi-bold t-color">
                  <div>{name}</div>
                  <img
                    src={`/partners/solutions/${key}-arrow.png`}
                    alt="right arrow"
                  />
                </div>
                <div className="hoverContent">
                  <div
                    className="name font-28 semi-bold"
                    style={{ background: hoverColor }}
                  >
                    {name}
                  </div>
                  <ul className="mt-64">
                    <Drop
                      color={hoverColor}
                      style={{ top: "-64px", left: "60px" }}
                    />
                    {description.map(c => (
                      <li key={c} className="mt-24">
                        {c}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

const ServiceM = () => {
  const [active, setActive] = useState("")
  return (
    <div className="solutionsPartner-services">
      <div className="semi-bold font-36 t-color">{text.services.title}</div>
      <div>
        {text.services.content.map(
          ({ name, description, key, color, hoverColor }, index) => (
            <div
              className={`solutionsPartner-services-item ${
                true ? "active" : ""
              }`}
              onClick={() => {
                window.open(url[key].link)
              }}
              onMouseEnter={() => {
                setActive(key)
              }}
            >
              <div className="solutionsPartner-services-visible">
                <div className="pic-container" style={{ background: color }}>
                  <img src={`/partners/solutions/${key}.svg`} alt={name} />
                </div>
                <div className="text-container font-28 semi-bold t-color">
                  <div>{name}</div>
                  <img
                    src={`/partners/solutions/${key}-arrow.png`}
                    alt="right arrow"
                  />
                </div>
                <div className="hoverContent">
                  <div
                    className={`name font-28 semi-bold name-${index}`}
                    style={{ background: hoverColor }}
                  >
                    {name}
                  </div>
                  <ul className="mt-64">
                    <Drop
                      color={hoverColor}
                      style={{ top: "-64px", left: "60px" }}
                    />
                    {description.map(c => (
                      <li key={c} className="mt-24">
                        {c}
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className={`hoverContent-bottom hoverContent-bottom-${index}`}
                >
                  Learn More
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

const OtherServices = () => (
  <div className="solutionsPartner-otherServices">
    <Drop style={{ top: 0 }} color="rgb(231,234,255)" />
    <div className="semi-bold font-36 t-color">{text.otherServices.title}</div>
    <div className="mt-40">
      {text.otherServices.content.map(({ name, description, key }) => (
        <div
          className="solutionsPartner-otherServices-item semi-bold"
          onClick={() => {
            navigate(url[key].link)
          }}
        >
          <picture>
            <img
              src={`/partners/solutions/${key}.svg`}
              alt={name}
              style={{ width: "180px" }}
            />
          </picture>
          <div className="font-20 t-color mt-24">{name}</div>
          <div className="mt-24 block-middle" style={{ maxWidth: "235px" }}>
            {description}
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default function Home({ data }) {
  return (
    <div className="solutionsPartner">
      <SEO {...text.seo} />
      <SolutionTopBanner
        {...text.topBanner}
        bgColor="linear-gradient(#F5F6FF 0%,#E7EAFF 100%)"
        bgPic="/partners/solutions/topBanner-bg.svg"
        height={662}
        pic={{
          src: "/partners/solutions/topBanner-pic.png",
          style: { position: "absolute", bottom: "-50px", right: 0 },
        }}
        bannerPic={{
          src: `/partners/solutions/topBanner-bg-m.png`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        button={PartnerPopButton}
        source="solutionPartner"
      />
      <Feature />
      {/* <MapContainer white={true} /> */}

      <Join data={data.strapiIndex.solutionPartnerLogos} />
      {/* <MiddleBanner /> */}
      {/* <div className="only-desktop-solution">
        <Service />
      </div>
      <div className="only-mobile-solution">
        <ServiceM />
      </div> */}

      {/* <OtherServices /> */}
      <PartnerPageForm {...text.form} source="solutionPartner" />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    strapiIndex {
      solutionPartnerLogos {
        id
        localFile {
          publicURL
        }
        alternativeText
      }
    }
  }
`
